
import {FaHome} from 'react-icons/fa'
import {FaPhone} from 'react-icons/fa'
import {AiTwotoneMail} from 'react-icons/ai'
import './Contact.css'
function Contact() {
  return ( 
    <div className=" contact-contact">
    <div className='home-contact-main-content'>
      <h1 className='home-class-h1'>#Contact-Us</h1>
      <p><span className='location-header'><FaHome/> Address:</span> Arround Tafo, Addis Ababa,, Ethiopia</p>

      <p className='contact-p'><span className='location-header'><AiTwotoneMail/> Email:</span> <span className='email'>nishan2013@gmail.com</span></p>

      <p className='contact-p'><span className='location-header'><FaPhone/> Phone:</span> 0941475682 / 0943513135</p>

    </div>
  </div>
   );
}

export default Contact;