
export const signInValidate = (value) =>{
  const errors = {};
  const regex = /^[^\s@]+@[^\s@]+.[^\s@]{6,}$/i;
  if(!value.email){
    errors.email = '* email is required!';
  }else if(!regex.test(value.email)){
    errors.email = '* this is not a valide email format!';
  }
  else{
    errors.email = ''
  }
  if(!value.password){
    errors.password = '* password is required!';
  }else if(value.password.length < 4){
    errors.password = '* password must be 4-8 characters!';
  }else if(value.password.length > 8){
    errors.password = '* password must be 4-8 characters!';
  }
  else{
    errors.password = ''
  }
  return errors;
}