import img1 from '../image/bag1.jpg'
import img2 from '../image/bag2.jpg'
import img3 from '../image/bag3.jpg'
import img4 from '../image/bag4.jpg'
import img5 from '../image/bag5.jpg'
import img6 from '../image/bag6.jpg'
import img7 from '../image/bag7.jpg'
import img8 from '../image/bag8.jpg'

const data = {
  productItems: [
    {
      id: '1',
      image: img1,
      name: 'hand-bag ( females\' )',
      price: 2400
    },
    {
      id: '2',
      image: img2,
      name: 'hand-bag ( for both )',
      price: 3500
    },
    {
      id: '3',
      image: img3,
      name: 'hand PC bag',
      price: 1800
    },
    {
      id: '4',
      image: img4,
      name: 'back-bag ( for both )',
      price: 2000
    },
    {
      id: '5',
      image: img5,
      name: 'arm-bag ( females\' )',
      price: 2300
    },
    {
      id: '6',
      image: img6,
      name: 'hand PC bag',
      price: 1700
    },
    {
      id: '7',
      image: img7,
      name: 'hand-bag ( males\' )',
      price: 1700
    },
    {
      id: '8',
      image: img8,
      name: 'hand-bag ( females\' )',
      price: 2500
    }
  ]
}
export default data;