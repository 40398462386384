import './Components/Forms/SignIn/SignIn.css'
import './Components/Forms/SignUp/SignUp.css'
import './Components/Navbar/Navbar.css'
import React, { createContext, useState } from 'react';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Components/Navbar/Navbar';
import SignIn from "./Components/Forms/SignIn/SignIn";
import SignUp from './Components/Forms/SignUp/SignUp';
import {BrowserRouter, Routes, Route, useNavigate} from 'react-router-dom'
import Shop from './Components/Shops/Shop';
import Cart from './Components/Cart/Cart';
import Contact from './Components/Contact/Contact';
import data from './Components/Data/Data';
import About from './Components/About/About';
import Home from './Components/Home/Home';


export const shpContext = createContext();

function App() { 
  const {productItems} = data;
  const [cartItems, setCartItems] = useState([]);
  const [counter, setCounter] = useState(0);

  const handdleAddProduct = (product) =>{
    const productExist = cartItems.find((item) => item.id === product.id);
    if(productExist){
      setCartItems(cartItems.map((item) => item.id === product.id ? 
      {...productExist, quantity: productExist.quantity + 1} : item))
    }else{
      setCartItems([...cartItems, {...product, quantity: 1}]);
    }
    setCounter(counter => counter + 1);
  }

  const handdleRemoveProduct = (product)=>{
    const productExist = cartItems.find((item) => item.id === product.id);
    if(productExist.quantity === 1){
      setCartItems(cartItems.filter((item) => item.id !== product.id))
    }
    else{
      setCartItems(cartItems.map((item) => item.id === product.id ? 
      {...productExist, quantity: productExist.quantity - 1} : item))
    }
    setCounter(counter => counter - 1);
  }

  
  const deletCartItem = ()=>{
    setCartItems([])
    setCounter(0);
  }
 
  return (
    <>
      <ToastContainer position ="bottom-right"/>
      <shpContext.Provider value={{counter, productItems, handdleAddProduct, cartItems,   deletCartItem, handdleRemoveProduct}}>
        <BrowserRouter>
          <Navbar/>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/shop' element={<Shop/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/cart' element={<Cart/>}/>
            <Route path='/login' element={<SignIn/>}/>
            <Route path='/signup' element={<SignUp/>}/>
          </Routes>
        </BrowserRouter>
      </shpContext.Provider>
    </>
  );
};
export default App;

