import { shpContext } from '../../App';
import './Shop.css'
import React, { useContext, useRef } from "react";
import {FaHome} from 'react-icons/fa'
import {FaPhone} from 'react-icons/fa'
import {AiTwotoneMail} from 'react-icons/ai'

const Shop = () =>{
  const {productItems, handdleAddProduct} = useContext(shpContext);

  const addToCart = useRef();
  const overlay = useRef();

  return(
    <>
      <div className='product-container'>
        <h2 className='product-h2'>Shop Products</h2>
        <div className="product-products">
          {productItems.map((productItem, index) =>(
            <div className="product-card" key={productItem.id}>
              <div className="product-image">
                <img src = {productItem.image}/>
              </div>
              <div className="product-name">
                <p>{productItem.name}</p>
              </div>
              <div className="product-price">{productItem.price} ETB</div>
              <div className="add-to-cart-btn">
                <button 
                onClick={()=>handdleAddProduct(productItem)} 
                className="product-button" ref={addToCart}>
                  add to cart
                  <span className='add-cart-overlay' ref={overlay}>added</span>
                </button>
              </div>
            </div>
          )
          )}
        </div>

      </div>  
      <div className=" home-contact">
    <div className='home-contact-main-content'>
      <h1 className='home-class-h1'>#Contact-Us</h1>
      <p><span className='location-header'><FaHome/> Address:</span> Arround Tafo, Addis Ababa,, Ethiopia</p>

      <p className='contact-p'><span className='location-header'><AiTwotoneMail/> Email:</span> <span className='email'>nishan2013@gmail.com</span></p>

      <p className='contact-p'><span className='location-header'><FaPhone/> Phone:</span> 0941475682 / 0943513135</p>

      <marquee className= 'marquee-home'>nishan 2014 E.C</marquee>

    </div>
  </div>
        {/* <Products 
          productItems = {productItems} 
          handdleAddProduct = {handdleAddProduct}>
        </Products> */}
      
    </>
  )
}
export default Shop;