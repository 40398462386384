import { shpContext } from '../../App';
import './Cart.css'
import React, { useContext } from "react"; 

const Cart = () => {

  const {cartItems, handdleAddProduct, handdleRemoveProduct, deletCartItem} = useContext(shpContext);

  let totalPrice = cartItems.reduce((price, item) => price + item.price * item.quantity, 0);

  return ( 
    <div className='cart-container'>
      <div className="cart-items">
        <div className="cart-items-header">
          Your Cart
        </div>
        
        <div>
          {cartItems.length === 0 && (
            <div className="cart-item-empty">
              Empty Cart
            </div>
          )}
        </div>

        <div className="nn">
          {cartItems.map((item) =>(
            <div key={item.id} className="cart-items-list">
              <img src = {item.image} className="cart-items-image"/>
              <div className='cart-tem-detail'>
                <div  className='cart-item-name'>{item.name}</div>
                <div className="cart-item-price">{item.quantity*item.price} ETB</div> 
              </div> 
              <div className='cart-product-quantity-controler'>
                <button 
                  className="cart-items-add" 
                  onClick={() => handdleAddProduct(item)}>
                    +
                </button>
                <button className="cart-items-quantity">{item.quantity}</button>
                <button 
                  className="cart-items-remove" 
                  onClick={() => handdleRemoveProduct(item)}>
                    <div className='minus'></div>
                </button>
              </div>
            </div>
          ) )}
        
          
        </div>
        <div className="total-price-container">
          <div className='total-price-detail'>
            <div className="cart-item-total-price-title">
              Total Price:
            </div>
            <div className="cart-item-total-price">
              {totalPrice} ETB
            </div>
            </div>
            <button type='submit' className='buy-btn'>Buy Now</button>
        </div>
        <button className="removeItem" onClick={deletCartItem}>
            x
        </button>
        {/* <button type='submit' className='buy-btn'>Buy Now</button> */}
      </div>
    </div>
   );
}

export default Cart;