import { useEffect, useState } from "react";
import axios from 'axios'
import { signUpValidate } from "../Validation/SignUpValidate";
import { useNavigate } from "react-router-dom";

function SignUp() {
  const [values, setValues] = useState(
    {
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    });
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [registerStatus, setRegisterStatus] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) =>{
    const {name, value} = event.target;
    setValues({...values, [name]: value})
  }

  const register = (event)=>{
    event.preventDefault();
    setIsSubmit(true);
    setErrors(signUpValidate(values));

  }
  useEffect(()=>{
    
  isSubmit && ((errors.firstName === "" && errors.lastName === "" && errors.email === "" && errors.password === "")?(
      axios.post("http://localhost:3001/register",values).then((response)=>{
        if(response.data.message){
          setRegisterStatus(response.data.message)
        }else{
          setRegisterStatus("Account Created Successfully!");
          setValues({});
          navigate('/');
        }
      })):setRegisterStatus("Account not Created, Try again!"));

  },[isSubmit,errors])


  return ( 
    <div className="sign-up">
      <div className="form-container">
        <div className="trial-alert">{registerStatus}</div>
        <form>
          <h1>Sign-Up</h1>
          <div className="ui-divider"></div>
          <div className="ui-form">

            <div className="field">
              <label>First Name</label>
              <input type="text" name="firstName" placeholder="First Name" required
                value={values.firstName || ''}
                onChange={handleChange}/>
                <span className="error-alert">{errors.firstName}</span>
            </div>

            <div className="field">
              <label>Last Name</label>
              <input type="text" name="lastName" placeholder="Last Name" required
                value={values.lastName || ''}
                onChange={handleChange}/>
                <span className="error-alert">{errors.lastName}</span>
            </div>

            <div className="field">
              <label>Email</label>
              <input type="email" name="email" placeholder="Email" required
                value={values.email || ''}
                onChange={handleChange}/>
                <span className="error-alert">{errors.email}</span>
            </div>

            <div className="field">
              <label>Password</label>
              <input type="password" name="password" placeholder="Password" required
                value={values.password || ''}
                onChange={handleChange}/>
                <span className="error-alert">{errors.password}</span>
            </div>                                      
            <button type='submit' className="submit-btn" onClick={register}>Sign-Up</button>
          </div>
        </form>
      </div>
    </div>
   );
}

export default SignUp;