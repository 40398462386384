
export const signUpValidate = (value) =>{
  const errors = {};
  const regex = /^[^\s@]+@[^\s@]+.[^\s@]{6,}$/i;

  if(!value.firstName){
    errors.firstName = '* first name is required!'
  }
  else{
    errors.firstName = ''
  }
  if(!value.lastName){
    errors.lastName = '* last name is required!'
  }else{
    errors.lastName = ''
  }
  if(!value.email){
    errors.email = '* email is required!';
  }else{
    errors.email = ''
  }
  if(!value.password){
    errors.password = '* password is required!';
  }else if(value.password.length < 4){
    errors.password = '* password must be 4-8 characters!';
  }else if(value.password.length > 8){
    errors.password = '* password must be 4-8 characters!';
  }
  else{
    errors.password = ''
  }
  
  return errors;
}