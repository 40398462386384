import './Home.css'
import img from '../image/background-img.png'
import {toast} from 'react-toastify'
import {FaHome} from 'react-icons/fa'
import {FaPhone} from 'react-icons/fa'
import {AiTwotoneMail} from 'react-icons/ai'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
function Home() {

  const [state, setState] = useState({
    email: '',
  });
  const {email} = state;


  // handle change ====================
  const handleChange = (e)=>{
    const {name, value} = e.target;
    setState({...state, [name]: value});
  }
  const handleSubmit = (event)=>{
    event.preventDefault();
    if(email == ''){
      toast.error('Please write your email!')
    }else{
      toast.success('You registered Successfully!')
    }
  }
  return ( 
    <>
      <div className="homemain-container" style={{backgroundImage: 'url(' + img + ')'}}>
        <div className='homemain-part-one'>
          <div  className='homemain-head'><span className='homemain-company-name'>NISHAN</span> LEATHER</div>
          <div  className='homemain-quality'>QUALITY PRODUCTS</div>
          <p className='homemain-p'>come and visit nishan, have upto 10% off!</p>
          <NavLink to='/shop'><button className='homemain-button'>Vist Us Today</button></NavLink>
        </div>

        <div className='homemain-part-two'>
          <div className='homemain-newsletter-head'>Sign Up For News-Letter</div>
          <p className='homemain-newsletter-p'>
            Get Email updates for latest product offers </p>

          <form onSubmit={handleSubmit} className='homemain-form'>
            <input type='email' name='email' className='homemain-input' placeholder='Your Email Address' onChange={handleChange} value={email || ''}required/>
            <button className='homemain-btn' type='submit'>Sign Up</button>
          </form>

        </div>
      </div>

      <div className=" home-contact">
    <div className='home-contact-main-content'>
      <h1 className='home-class-h1'>#Contact-Us</h1>
      <p><span className='location-header'><FaHome/> Address:</span> Arround Tafo, Addis Ababa,, Ethiopia</p>

      <p className='contact-p'><span className='location-header'><AiTwotoneMail/> Email:</span> <span className='email'>nishan2013@gmail.com</span></p>

      <p className='contact-p'><span className='location-header'><FaPhone/> Phone:</span> 0941475682 / 0943513135</p>

      <marquee className= 'marquee-home'>nishan 2014 E.C</marquee>

    </div>
  </div>
    </>
   );
}

export default Home;