import { useContext, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { shpContext } from "../../App";
import logo from '../image/logo.png'
import {FaCartPlus} from 'react-icons/fa'

function Navbar() {

  const {counter} = useContext(shpContext);
  
  const rowLinks = useRef();
  const columnLinks = useRef();
  const menuBtn = useRef();
  const removeLinks = useRef();

  const menuBars = ()=>{
    columnLinks.current.style.right = '0';
  }
  const removeLink= ()=>{
    columnLinks.current.style.right = '-101%';
  }


  return ( 
    <div className="header">
      <div className="logo">
        <img src={logo}></img>
        {/* <NavLink to='/'><span>NI</span>SHAN</NavLink> */}
      </div>
      
      <div className="link-container">
        <ul>
          <span ref={rowLinks} className="link-to-be-hidden">
            <li><NavLink to='/'>Home</NavLink></li>
            <li><NavLink to='/about'>About</NavLink></li>
            <li><NavLink to='/shop'>Shop</NavLink></li>
            <li><NavLink to='/contact'>Contact</NavLink></li>
          </span>
          <li><NavLink to='/cart' className='cart-icon'><FaCartPlus/> <span className="counter">{counter}</span></NavLink></li>             
        </ul>
        <div className="menu-container">
          <button ref={menuBtn} className="menu" onClick={menuBars} >
            <div className="menu-bar"></div>
            <div className="menu-bar"></div>
            <div className="menu-bar"></div>
          </button>
        </div>
      </div>

      <div ref={columnLinks} className="narrow-divice-link-container">
        <ul>
          <li><NavLink to='/'>Home</NavLink></li>
          <li><NavLink to='/about'>About</NavLink></li>
          <li><NavLink to='/shop'>Shop</NavLink></li>
          <li><NavLink to='/contact'>Contact</NavLink></li>           
        </ul>
        <button className="remove-link" ref={removeLinks} onClick={removeLink}>x</button>
      </div>
    </div>
   );
}
export default Navbar;