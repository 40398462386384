import { useState, useEffect } from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import axios from 'axios'
import { signInValidate } from '../Validation/SignInValidate';

function SignIn() {
  
  const [values, setValues] = useState(
    {
      email: '',
      password: '',
    });
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loginStatus, setLoginStatus] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) =>{
    const {name, value} = event.target;
    setValues({...values, [name]: value})
  }

  const login = (event)=>{
    event.preventDefault();
    setIsSubmit(true);
    setErrors(signInValidate(values));

  }
  useEffect(()=>{
    
  isSubmit && (
    ( errors.email === "" && errors.password === "") ? (
    axios.post("http://localhost:3001/login",values).then((response)=>{
      response.data.message ? setLoginStatus(response.data.message) :
      setLoginStatus("Login Successfully!");

      response.data.message ? setValues({...values}) : setValues({}) ;
      response.data.message ? navigate('/login') : navigate('/home') ;
   
    })):setLoginStatus("")

  );

  },[isSubmit,errors])

  return ( 
    <div className="body">
      <div className="form-container">
      <div className='trial-alert'>{loginStatus}</div>
        <form >
          <h1>Log-In</h1>
          <div className="ui-divider"></div>
          <div className="ui-form">
            <div className="field">
              <label>Email</label>
              <input type="email" name="email" placeholder="Email" required
                value={values.email || ''}
                onChange={handleChange}/>
                <span className="error-alert">{errors.email}</span>
            </div>

            <div className="field">
              <label>Password</label>
              <input type="password" name="password" placeholder="Password" required
                value={values.password || ''}
                onChange={handleChange}/>
                <span className="error-alert">{errors.password}</span>
            </div>
            <button type='submit' className="submit-btn" onClick={login}>Submit</button>

            <div className="creat-new">
              * If you didn't have an account before, <span>creat new account.</span>
            </div>

            <button className="creat-account">
              <NavLink to='/signup'>Create Account</NavLink>
            </button>
          </div>
        </form>
      </div>
    </div>
   );
}

export default SignIn;