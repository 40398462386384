import img from '../image/background-img.png'
import img2 from '../image/about-image.png'
import {FaHome} from 'react-icons/fa'
import {FaPhone} from 'react-icons/fa'
import {AiTwotoneMail} from 'react-icons/ai'
import './About.css'
function About() {
  return ( 
    <>
      <div className="about" style={{backgroundImage: 'url(' + img + ')'}}>
        <h1  className='about-h1'>#knowUs</h1>
        <p className='about-p'>come and visit nishan, have upto 10% off!</p>
      </div>

      <section id="about-content">
        <div className='image-container'><img src={img2} alt="loading"/> </div>
        <div className='about-main-content'>
            <div id= 'about-h2'>Who We Are?</div>
            <p className='about-p'>Nisha as a company has started its work on June 20/2014 ec.But it was in the thought of the five owners of nishan those are; Moges Tefera, Amsalu Siyum, Beelhem Asfaw, Betelhem Guade and Ermias about four years before its born date. Its main goal is to address quality leather products for our customers, primerly. Now nishan is producing different leather products as per its own design as well as according to our customers order of design.
            </p>
             <marquee id='about-marquee'>Come and visit Nishan, You will have 10% off!</marquee>
        </div>
      </section>
      <div className=" home-contact">
        <div className='home-contact-main-content'>
          <h1 className='home-class-h1'>#Contact-Us</h1>
          <p><span className='location-header'><FaHome/> Address:</span> Arround Tafo, Addis Ababa,, Ethiopia</p>

          <p className='contact-p'><span className='location-header'><AiTwotoneMail/> Email:</span> <span className='email'>nishan2013@gmail.com</span></p>

          <p className='contact-p'><span className='location-header'><FaPhone/> Phone:</span> 0941475682 / 0943513135</p>

          <marquee className= 'marquee-home'>nishan 2014 E.C</marquee>

        </div>
      </div>
    </>
   );
}

export default About;